import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import options from "./options";
import Scrollbars from "../../components/utility/customScrollBar.js";
import Menu from "../../components/uielements/menu";
import IntlMessages from "../../components/utility/intlMessages";
import SidebarWrapper from "./sidebar.style";
import appActions from "../../redux/app/actions";
import Logo from "../../components/utility/logo";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const { Sider } = Layout;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed
} = appActions;
const stripTrailingSlash = str => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  state = {
    filteredOptions:[]
  };
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }
  componentDidMount(){
    let userObj = localStorage.getItem("user");
    if (!userObj) return;
    userObj = JSON.parse(userObj);
    //let opcionesCopy = options.slice();
    let opcionesCopy = [];
    for(let i=0; i<options.length; i++) {
      opcionesCopy.push({...options[i]});
    }

    // NUEVA IMPLEMENTACION POR PRIVILEGIOS
    let filteredOptions = opcionesCopy.slice();
    //Filtrar por roles las opciones del menu actuales
    //Filtramos primero los menus padres
    
    for (const mainMenu of filteredOptions) {
      mainMenu.children = mainMenu.children.filter(function(singleOption){
        let returnOption = false;
        if (userObj.privilegios && userObj.privilegios.length > 0)
        {
          for(let userPrivilegio of userObj.privilegios){
            if (userPrivilegio === singleOption.privilegio || singleOption.privilegio === "*"){
              returnOption = true;
              break;
            }
          }
        }
        
        // Revisar si tiene submenus
        if(returnOption && singleOption.children && Array.isArray(singleOption.children)) {
          singleOption.children = singleOption.children.filter(function(menu){
            let ro = false;
            if (userObj.privilegios && userObj.privilegios.length > 0)
            {
              for(let userPrivilegio of userObj.privilegios){
                if (userPrivilegio === menu.privilegio || menu.privilegio === "*"){
                  ro = true;
                  break;
                }
              }
            }
            return ro;
          });
        }
        return returnOption;
      });
    }

    for(const ch of filteredOptions) {
      let totalMenus = 0;
      if(ch.children && ch.children.length) {
        for(const ch1 of ch.children) {
          if(ch1.children) {
            for(const ch2 of ch1.children) {
              if(ch2.children && ch2.children.length) {
              } else {
                totalMenus++;
              }
            }
          } else {
            totalMenus++;
          }
        }
      }
      ch.totalMenus = totalMenus;
    }
    this.setState({ filteredOptions });
  }

  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      key => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = key => {
    const map = {
      sub3: ["sub2"]
    };
    return map[key] || [];
  };
  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { key, label, leftIcon, children, totalMenus } = singleOption;
    const url = stripTrailingSlash(this.props.url);

    if (children && totalMenus > 0)
      return (
        <SubMenu
          key={key}
          title={
            <span className="isoMenuHolder" style={submenuColor}>
              {/*<i className={leftIcon} />*/}
              <span className="nav-text">
                <IntlMessages id={label} />
              </span>
            </span>
          }
        >
          {children.map(child => {

            // Mostrar submenus
            if(child.children && Array.isArray(child.children)) {
              return (
                <MenuItemGroup
                  key={child.key}
                  title={<IntlMessages id={child.label} />}
                >
                  {child.children.map(grandChild => {
                    const linkTo = grandChild.withoutDashboard
                      ? `/${grandChild.key}`
                      : `${url}/${grandChild.key}`;
                    return (
                      <Menu.Item style={submenuStyle} key={grandChild.key}>
                        <Link style={submenuColor} to={linkTo}>
                          {/*<i className={grandChild.leftIcon} />&nbsp;&nbsp;&nbsp;*/}
                          <IntlMessages id={grandChild.label} />
                        </Link>
                      </Menu.Item>
                    );
                  })}
                </MenuItemGroup>
              );
            }

            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`;
            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link style={submenuColor} to={linkTo}>
                  {/*<i className={child.leftIcon} />&nbsp;&nbsp;&nbsp;*/}
                  <IntlMessages id={child.label} />
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );

    if(totalMenus)
      return (
        <Menu.Item key={key}>
          <Link to={`${url}/${key}`}>
            <span className="isoMenuHolder" style={submenuColor}>
              {/*<i className={leftIcon} />*/}
              <span className="nav-text">
                <IntlMessages id={label} />
              </span>
            </span>
          </Link>
        </Menu.Item>
      );
    return <></>
  };
  render() {
    const { app, toggleOpenDrawer, customizedTheme, height } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? "vertical" : "inline";

    const onMouseEnter = event => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const styling = {
      backgroundColor: customizedTheme.backgroundColor
    };
    const submenuStyle = {
      backgroundColor: "rgba(0,0,0,0.2)",
      color: customizedTheme.textColor,
      paddingLeft: '10px'
    };
    const submenuColor = {
      color: customizedTheme.textColor
    };
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={240}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: height - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {this.state.filteredOptions.map(singleOption => 
                this.getMenuItem({ submenuStyle, submenuColor, singleOption })
              )}
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  state => ({
    app: state.App,
    customizedTheme: state.ThemeSwitcher.sidebarTheme,
    height: state.App.height
  }),
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(Sidebar);
