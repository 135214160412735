import React from 'react';
import { Modal } from "antd";

export default {
  showSuccess(text) {
    Modal.success({
      title: 'Bien!',
      content: (
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      ),
    });
  },
  showInfo(text) {
    Modal.info({
      title: 'Información',
      content: (
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      ),
    });
  },
  showError(text) {
    Modal.error({
      title: 'Upss!',
      content: (
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      ),
    });
  },
  showErrorList(textList) {
    let text = '';
    for(let i=0; textList.length > i; i++) {
      text += '<p>'+textList[i]+'</p>'
    }
    this.showError(text);
  }
}