import React, { Component } from 'react';
import { Popover, Icon, Statistic, Row, Col, Typography } from 'antd';
import { connect } from 'react-redux';
import TopbarDropdownWrapper from './topbarDropdown.style';
import httpHandler from "../../api/asyncHttpHandler.js";
import { withRouter } from 'react-router-dom';
import LayoutWrapper from "../../components/utility/layoutWrapper";
import { tankLitresTimer } from "../../settings";
import Modals from "../../components/feedback/modal";
import ModalStyle from "../../containers/Feedback/Modal/modal.style";
import WithDirection from "../../settings/withDirection";
import { formatNumber } from '../../helpers/utility';

const { Text } = Typography;

class TopbarLitresAlert extends Component {
  isoModal = ModalStyle(Modals);
  Modal = WithDirection(this.isoModal);
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.state = {
      tankLitresInterval:"",
      tankLitres: {litres: 0, price: 0, min_litres: 0},
      visible:false,
      modalVisible:false,
    };
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }
  getTankLitres = async () =>{
    this.setState({loading: true});
    const parsedResponse = await httpHandler.petition('getTankLitres', 'POST');
    this.setState({loading: false});
    if(parsedResponse === null || parsedResponse.HasError === true) return;
    this.setState({
      tankLitres : parsedResponse
    });
  }
  getTankLitres = this.getTankLitres.bind(this);
  componentDidMount(){
    let userObj = localStorage.getItem("user");
    if (!userObj){
      this.props.history.push("/");
      return;
    }
    this.getTankLitres();
    var tanLitresIntervalId = setInterval(this.getTankLitres, tankLitresTimer.value);

    this.setState({tanLitresInterval: tanLitresIntervalId});

  }
  componentWillUnmount() {
    clearInterval(this.state.tanLitresInterval);
  }
 
  openModal = () =>{
    this.setState({ modalVisible: true});
  }
  closeModal = () =>{
    this.setState({ modalVisible: false});
  }
  render() {
    const {showTank} = this.props;
    const content = (
      <TopbarDropdownWrapper className="topbarNotification">
        <div style={{padding: '1em'}}>
          <Row gutter={8} type="flex" align="middle">
            <Col xs={8}>
              <Text type="secondary" style={{textAlign: 'right'}}>Tanque Pension:</Text>
            </Col>
            <Col xs={16}>
              <Statistic title="" suffix="L" value={ this.state.tankLitres ? this.state.tankLitres.litres : 0 } valueStyle={{textAlign: 'right'}}/>
            </Col>
          </Row>
          <Row gutter={8} type="flex" align="middle">
            <Col xs={8}>
              <Text type="secondary" style={{textAlign: 'right'}}>Precio:</Text>
            </Col>
            <Col xs={16}>
              <Statistic title="" prefix="$" value={ this.state.tankLitres ? this.state.tankLitres.price: 0 } valueStyle={{textAlign: 'right'}}/>
            </Col>
          </Row>
          <Row gutter={8} type="flex" align="middle">
            <Col xs={8}>
              <Text type="secondary" style={{textAlign: 'right'}}>Minimo (Alerta):</Text>
            </Col>
            <Col xs={16}>
              <Statistic title="" suffix="L" value={ this.state.tankLitres ? this.state.tankLitres.min_litres: 0 } valueStyle={{textAlign: 'right'}}/>
            </Col>
          </Row>
        </div>
      </TopbarDropdownWrapper>
    );
    return (
      <LayoutWrapper>
        {
          showTank &&
          <Popover
            content={content}
            trigger="click"
            visible={this.state.visible}
            onVisibleChange={this.handleVisibleChange}
            placement="bottomLeft"
          >
            <Row gutter={8} type="flex" align="middle">
              <Col xs={24} md={0}>
                <Icon type="bg-colors" />
              </Col>
              <Col xs={0} md={10}>
                <Text type="secondary" style={{textAlign: 'right'}}>Tanque Pension:</Text>
              </Col>
              <Col xs={0} md={14}>
                <Statistic title="" suffix="L" value={ this.state.tankLitres ? this.state.tankLitres.litres : 0 } valueStyle={{textAlign: 'right'}}/>
              </Col>
            </Row>
          </Popover>
        }
        <Row gutter={8} type="flex" align="middle" style={{marginLeft: '1em'}}>
          <Col xs={0} md={8}>
            <Text type="secondary" style={{textAlign: 'right'}}>Precio:</Text>
          </Col>
          <Col xs={0} md={16}>
            <Statistic title="" prefix="$" value={ this.state.tankLitres ? this.state.tankLitres.price: 0 } valueStyle={{textAlign: 'right'}}/>
          </Col>
        </Row>
      </LayoutWrapper>
    );
  }
}

export default withRouter(connect(state => ({
  ...state.App,
  customizedTheme: state.ThemeSwitcher.topbarTheme,
}))(TopbarLitresAlert));