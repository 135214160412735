import React, { Component } from 'react';
import { Popover } from 'antd';
import { connect } from 'react-redux';
import IntlMessages from '../../components/utility/intlMessages';
import TopbarDropdownWrapper from './topbarDropdown.style';
import httpHandler from "../../api/asyncHttpHandler.js";
import { withRouter } from 'react-router-dom';
import Modals from "../../components/feedback/modal";
import ModalStyle from "../../containers/Feedback/Modal/modal.style";
import WithDirection from "../../settings/withDirection";
import LayoutWrapper from "../../components/utility/layoutWrapper";
import ContentHolder from '../../components/utility/contentHolder';
import TableWrapper from '../Tables/antTables/antTable.style';
import { notificationTimer } from "../../settings";
import {
  DateCell,
  ImageCell,
  LinkCell,
  TextCell
} from '../../components/tables/helperCells';
class TopbarNotification extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);

    this.state = {
      visible: false,
      notifications: [],
      allNotifications:[],
      intervalId:"",
      totalNotifications: 0,
      user_id:"",
      modalVisible:false,
      tankLitresInterval:"",
      tankLitres:""
    };
  }
  isoModal = ModalStyle(Modals);
  Modal = WithDirection(this.isoModal);

  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }
  renderCell = (object, type, key) => {
    const value = object[key];
    switch (type) {
      case 'ImageCell':
        return ImageCell(value);
      case 'DateCell':
        return DateCell(value);
      case 'LinkCell':
        return LinkCell(value);
      default:
        return TextCell(value);
    }
  };
  notificationColumns = [
    {
      title: " ",
      key: 'notification_color_table',
      width: 30,
      render: (text, record) => (
        <span>
          <i className={record.color==="red" ? "table-edit-icon ion-android-cancel color-red" : "table-edit-icon ion-android-done color-green"} />&nbsp;&nbsp; 
        </span>
      ),
    },
    {
      title: "Título",
      key: 'notification_title_table',
      width: 100,
      render: (text, record) => (
        <span className={ record.seen === 0 ? "bold" : "" }> {record.name}</span>
      ),
    },
    {
      title: "Mensaje",
      key: 'notification_message_table',
      width: 100,
      render: (text, record) => (
        <span className={ record.seen === 0 ? "bold" : "" }> {record.notification}</span>
      ),
    },
    {
      title: 'Acción',
      dataIndex: '',
      key: 'x',
      width: 100,
      render: (text, record) => (
        <div>
          <span className="table-enter-leave-demo-delete" onClick={(e) => this.seenNotification(record.id) }>
            <i className="table-edit-icon ion-android-done-all" />&nbsp;&nbsp; Marcar como leído <br/>
          </span>
        </div>
      ),
    }
  ];

  seenNotification = async (id) => {
    const parsedResponse = await httpHandler.petition('seenNotification', 'POST', { id: id });
    if(parsedResponse === null || parsedResponse.HasError === true) return;
    let notifications = this.state.allNotifications;
    notifications.map( item => {
      if (item.id === id) item.seen = 1;
    });
    this.setState({
      allNotifications: notifications
    });
    //getSellerNotificaions();
  }
  getSellerNotificaions = async (id = null) => {
    
    const parsedResponse = await httpHandler.petition('getNotificationsByUser', 'POST', { user_from: id === null ? this.state.user_id : id });
    if(parsedResponse === null || parsedResponse.HasError === true) return;
    let keyFix = 0;
    this.setState({
      notifications : []
    });

    const notifications = [];
    const allNotifications = [];

    this.setState({
      totalNotifications : parsedResponse.length
    });

    for(var i in parsedResponse){
      if (i>4)break;
      notifications.push({
        id:parsedResponse[i].id,
        name:parsedResponse[i].title,
        notification:parsedResponse[i].message,
        color:parsedResponse[i].color,
        key:parsedResponse[i].id,
        seen:parsedResponse[i].seen
      });
    }
    for(var i in parsedResponse){
      allNotifications.push({
        id:parsedResponse[i].id,
        name:parsedResponse[i].title,
        notification:parsedResponse[i].message,
        color:parsedResponse[i].color,
        key:parsedResponse[i].id,
        seen:parsedResponse[i].seen
      });
    }
    
    this.setState({
      notifications : notifications,
      allNotifications: allNotifications
    });
  }
  getDirectorNotifications = async () =>{
    const parsedResponse = await httpHandler.petition('getPendingPreauthorizations', 'POST');
    if(parsedResponse === null || parsedResponse.HasError === true) return;
    let keyFix = 0;
    this.setState({
      notifications : []
    });

    const notifications = [];

    this.setState({
      totalNotifications : parsedResponse.length
    });

    for(var i in parsedResponse){
      if (i>4)break;
      notifications.push({
        id:keyFix++,
        name:parsedResponse[i].title,
        notification:parsedResponse[i].message,
        color:parsedResponse[i].color,
        seen:parsedResponse[i].seen
      });
    }
    
    this.setState({
      notifications : notifications
    });
  }
  
  getDirectorNotifications = this.getDirectorNotifications.bind(this);
  componentDidMount(){
    let userObj = localStorage.getItem("user");
    if (!userObj){
      this.props.history.push("/");
      return;
    }
    userObj = JSON.parse(userObj);
    this.setState({
      user_id:userObj.id
    });
    if (userObj.rol === "Director"){
      this.getDirectorNotifications();
      var intervalId = setInterval(this.getDirectorNotifications, notificationTimer.value);
      this.setState({intervalId: intervalId});
    }else if (userObj.rol === "Vendedor" || userObj.rol === "VendedorOperaciones"){
      this.getSellerNotificaions(userObj.id);
      var intervalId = setInterval(this.getSellerNotificaions, notificationTimer.value);
      this.setState({intervalId: intervalId});
    }
  }
  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
    clearInterval(this.state.tanLitresInterval);
  }
 
  viewAllUrlHandler = () =>{
    const { history } = this.props;
    let userObj = localStorage.getItem("user");
    if (!userObj) return;
    userObj = JSON.parse(userObj);
    if (userObj.rol === "Director"){
      this.handleVisibleChange();
      history.push('/dashboard/orders_management');
    }
    if (userObj.rol === "Vendedor" || userObj.rol === "VendedorOperaciones"){
      this.handleVisibleChange();
      this.setState({ modalVisible: true});
      return;
      
    }
  }
  handleHideModal = () => {
    this.setState({ modalVisible: false });
  };
  render() {
    const { customizedTheme } = this.props;
    const content = (
      <TopbarDropdownWrapper className="topbarNotification">
        <div className="isoDropdownHeader">
          <h3>
            <IntlMessages id="sidebar.notification" />
          </h3>
        </div>
        <div className="isoDropdownBody">
          {this.state.notifications.map(notification => (
            <a className={ notification.color === "red" ? "notification-row-red isoDropdownListItem" : "notification-row-green isoDropdownListItem" } key={notification.id} href="# ">
              <h5>{notification.name}</h5>
              <p>{notification.notification}</p>
            </a>
          ))}
        </div>
        <a className="isoViewAllBtn" href="#" onClick={this.viewAllUrlHandler}>
          <IntlMessages id="topbar.viewAll" />
        </a>
      </TopbarDropdownWrapper>
    );
    return (
      
      <LayoutWrapper>

      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        placement="bottomLeft"
      >
        <div className="isoIconWrapper">
          <i
            className={ this.state.totalNotifications > 5 ? "ion-android-notifications color-red" : "ion-android-notifications color-blue" }
            style={{ color: customizedTheme.textColor }}
          />
          <span>{this.state.totalNotifications}</span>
        </div>
      </Popover>

        <this.Modal
            visible={this.state.modalVisible}
            maskClosable={false}
            width={1150}
            bodyStyle= {
              {
                padding : 30
              }
            }
            title={ "NOTIFICACIONES" }
            onOk={ this.handleHideModal }
            onCancel={ this.handleHideModal }
            footer={[
            ]}
        >
          <ContentHolder>
            <TableWrapper
                columns={this.notificationColumns}
                onChange={this.onChange}
                dataSource={this.state.allNotifications}
                className="isoSortingTable"
                empty="No hay registros"
            />
          </ContentHolder>
        </this.Modal>

      </LayoutWrapper>
    );
  }
}

export default withRouter(connect(state => ({
  ...state.App,
  customizedTheme: state.ThemeSwitcher.topbarTheme,
}))(TopbarNotification));
