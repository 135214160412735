
const options = [
  
  // * * * * * * * 
  // MENU PENSION
  // * * * * * * * 
  {
    key: 'Tableros',
    label: 'sidebar.dashboard',
    leftIcon: 'ion-paintbucket',
    //roles: ["Admin", "Director", "Vendedor", "VendedorPrecios", "VendedorOperaciones", "Anahuac"],
    children: [
      {
        key: '',
        label: 'sidebar.dashboard',
        leftIcon: 'ion-arrow-graph-up-right',
        privilegio:'tablero_tablero_ver'
        // roles:[ "Admin", "Director", "Vendedor", "VendedorPrecios", "VendedorOperaciones", "Cliente", "Anahuac" ]
      },
      {
        key: 'dashboard_director',
        label: 'sidebar.dashboard1',
        leftIcon: 'ion-arrow-graph-up-right',
        privilegio:'tablero_tablero_director_ver'
        // roles:[ "Admin", "Director" ]
      },
    ]
  },
  {
    key: 'pension',
    label: 'sidebar.pension',
    leftIcon: 'ion-paintbucket',
    //roles: ["Admin", "Director", "Vendedor", "VendedorPrecios", "VendedorOperaciones", "Despachador", "Operaciones", "OperacionesAnahuac", "Vigilante", "Cliente", "Cobranza", "ContabilidadS"],
    children: [
      {
        key: 'preauthorization',
        label: 'sidebar.preauthorization',
        leftIcon: 'ion-clipboard',
        privilegio:"pension_programar_carga_ver"
      },
    
      {
        key: 'charges',
        label: 'sidebar.charge_create',
        leftIcon: 'ion-android-bus',
        privilegio:"pension_generar_carga_ver"
      },
    
      {
        key: 'orders_management',
        label: 'sidebar.orders_management',
        leftIcon: 'ion-clipboard',
        privilegio:"pension_manejo_pedidos_ver"
      },
      /*{
        key: 'maintenance',
        label: 'sidebar.maintenance',
        leftIcon: 'ion-clipboard',
        roles:[ "Admin","Vendedor", "VendedorPrecios", "VendedorOperaciones"]
      },*/
      {
        key: 'users',
        label: 'sidebar.users',
        leftIcon: 'ion-android-person-add',
        privilegio: "pension_usuarios_ver" 
      },
      {
        key: 'shifts',
        label: 'sidebar.shifts',
        leftIcon: 'ion-android-contacts',
        privilegio: "pension_turnos_ver" 
      },
      {
        key: 'reservations',
        label: 'sidebar.reservations',
        leftIcon: 'ion-android-clipboard',
        privilegio: "pension_reservaciones_ver"
      },
      {
        key: 'customers',
        label: 'sidebar.customers',
        leftIcon: 'ion-android-contact',
        privilegio: "pension_clientes_ver"
      },
      {
        key: 'alerts',
        label: 'sidebar.alerts',
        leftIcon: 'ion-android-settings',
        privilegio: "pension_configuracion_tanque_ver"
      },
      {
        key: 'prices',
        label: 'sidebar.price',
        leftIcon: 'ion-android-settings',
        privilegio: "pension_configuracion_precios_ver"
      },
      {
        key: 'profile',
        label: 'sidebar.profile',
        leftIcon: 'ion-clipboard',
        privilegio: "*"
      },
      {
        key: 'create_suggestion',
        label: 'sidebar.create_suggestion',
        leftIcon: 'ion ion-ios-email-outline',
        privilegio: "*" 
      },
      {
        key: 'suggestions',
        label: 'sidebar.suggestions',
        leftIcon: 'ion ion-android-clipboard',
        privilegio: "pension_sugerencias_ver"
      },
      {
        key: 'cash_movement',
        label: 'sidebar.cash_movement',
        leftIcon: 'ion ion-android-clipboard',
        privilegio: "cash_movement_ver"
      },
      {
        key: 'gallery',
        label: 'sidebar.gallery',
        leftIcon: 'ion ion-ios-email-outline',
        privilegio: "pension_galeria_ver" 
      },
      {
        key: 'mapprices',
        label: 'sidebar.mapprices',
        leftIcon: 'ion-android-person-add',
        privilegio: "pension_mapa_precios_ver" 
      },
      {
        key: 'movimientos_bancarios',
        label: 'sidebar.movimientos_bancarios',
        leftIcon: 'ion-android-person-add',
        privilegio: "pension_movimientos_bancarios_ver" 
      },
      {
        key: 'productos',
        label: 'sidebar.productos_smg',
        leftIcon: 'ion-android-person-add',
        privilegio: "pension_productos_ver"
      },
    ],
  },

  // * * * * * * * 
  // MENU MAYOREO
  // * * * * * * * 
  {
    key: 'mayoreo',
    label: 'sidebar.mayoreo',
    leftIcon: 'ion-paintbucket',
    //roles: ["Admin", "Director", "Vendedor", "VendedorPrecios", "VendedorOperaciones", "Mayoreo", "Mayoreo1", "Cliente", "Cobranza"],
    children: [
      {
        key: '',
        label: 'sidebar.dashboard',
        leftIcon: 'ion-arrow-graph-up-right',
        privilegio: "mayoreo_tablero_ver"
      },
      {
        key: 'preauthorizationWholesale',
        label: 'sidebar.preauthorizationWholesale',
        leftIcon: 'ion-clipboard',
        privilegio: "mayoreo_solicitar_carga_mayoreo_ver"
      },
      {
        key: 'chargesWholesale',
        label: 'sidebar.charge_wholesale_create',
        leftIcon: 'ion-android-bus',
        privilegio: "mayoreo_programar_carga_mayoreo_ver"
      },
      {
        key: 'cartasporte',
        label: 'sidebar.cartasporte',
        leftIcon: 'ion-android-person-add',
        privilegio: "mayoreo_cartas_porte_ver"
      },
      {
        key: 'inventory',
        label: 'sidebar.inventory',
        leftIcon: 'ion-android-person-add',
        privilegio: "mayoreo_entradas_ver"
      },
      {
        key: 'operators',
        label: 'sidebar.operators',
        leftIcon: 'ion-android-person-add',
        privilegio: "mayoreo_operadores_ver"
      },
      {
        key: 'units',
        label: 'sidebar.units',
        leftIcon: 'ion-clipboard',
        privilegio: "mayoreo_unidades_ver"
      },
      {
        key: 'mayoreo_cartas_porte_remitentes',
        label: 'sidebar.mayoreo_cartas_porte_remitentes',
        leftIcon: 'ion-clipboard',
        privilegio: "mayoreo_cartas_porte_remitentes"
      },
      
    ],
  },
  // * * * * * * * 
  // ANAHUAC
  // * * * * * * * 
  {
    key: 'anahuac',
    label: 'sidebar.anahuac',
    leftIcon: 'ion-paintbucket',
    //roles:[ "Admin", "Director", "Vendedor", "VendedorPrecios", "Mayoreo", "OperacionesAnahuac", "Anahuac"],
    children: [
      {
        key: 'dispatch',
        label: 'sidebar.dispatch',
        leftIcon: 'ion-android-contact',
        privilegio: "anahuac_despacho_ver",
      },
      {
        key: 'payments',
        label: 'sidebar.payments',
        leftIcon: 'ion-android-contact',
        privilegio: "anahuac_cortes_ver", 
      },
      {
        key: 'inventory_pemex',
        label: 'sidebar.inventory_pemex',
        leftIcon: 'ion-android-contact',
        privilegio: "anahuac_entradas_pemex_ver"
      },
      {
        key: 'anahuac_remission',
        label: 'sidebar.dockCharge',
        leftIcon: 'ion-android-clipboard',
        privilegio: "anahuac_carga_muelle_ver" 
      },
    ],
  },
  // * * * * * * * 
  // EGRESOS
  // * * * * * * * 
  {
    key: 'egresos',
    label: 'sidebar.compras',
    leftIcon: 'ion-paintbucket',
    //roles:[ "Admin", "Director" ],
    children: [
      {
        key: 'egresos_requisiciones',
        label: 'sidebar.egresos_requisiciones',
        leftIcon: 'ion-android-clipboard',
        privilegio: "egresos_requisiciones_ver" 
      },
      {
        key: 'egresos_autorizar_requisiciones',
        label: 'sidebar.egresos_autorizar_requisiciones',
        leftIcon: 'ion-android-clipboard',
        privilegio: "egresos_autorizar_requisiciones_ver" 
      },
      {
        key: 'egresos_requisiciones_autorizadas',
        label: 'sidebar.egresos_requisiciones_autorizadas',
        leftIcon: 'ion-android-clipboard',
        privilegio: "egresos_requisiciones_autorizadas_ver" 
      },
      {
        key: 'egresos_ordenesdecompra',
        label: 'sidebar.egresos_ordenesdecompra',
        leftIcon: 'ion-android-clipboard',
        privilegio: "egresos_ordenes_compra_ver" 
      },
      {
        key: 'egresos_ordenesdecompra_prefijos',
        label: 'sidebar.egresos_ordenesdecompra_prefijos',
        leftIcon: 'ion-android-clipboard',
        privilegio: "egresos_ordenes_compra_ver" 
      },
      {
        key: 'egresos_requisicion_descripcion',
        label: 'sidebar.egresos_requisicion_descripcion',
        leftIcon: 'ion-android-clipboard',
        privilegio: "egresos_descripciones_ver" 
      },
      {
        key: 'egresos_requisicion_unidad',
        label: 'sidebar.egresos_requisicion_unidad',
        leftIcon: 'ion-android-clipboard',
        privilegio: "egresos_unidades_medida_ver" 
      },
      {
        key: 'egresos_requisicion_destinos',
        label: 'sidebar.egresos_requisicion_destinos',
        leftIcon: 'ion-android-clipboard',
        privilegio: "egresos_destinos_ver" 
      },
      {
        key: 'egresos_razones_sociales',
        label: 'sidebar.egresos_razones_sociales',
        leftIcon: 'ion-android-clipboard',
        privilegio: "egresos_razones_sociales_ver" 
      },
      {
        key: 'egresos_requisicion_equipos',
        label: 'sidebar.egresos_requisicion_equipos',
        leftIcon: 'ion-android-clipboard',
        privilegio: "egresos_equipos_ver" 
      },
      {
        key: 'egresos_requisicion_grupo',
        label: 'sidebar.egresos_requisicion_grupo',
        leftIcon: 'ion-android-clipboard',
        privilegio: "egresos_grupos_ver" 
      },
      {
        key: 'egresos_requisicion_proveedores',
        label: 'sidebar.egresos_requisicion_proveedores',
        leftIcon: 'ion-android-clipboard',
        privilegio: "egresos_proveedor_ver" 
      },
    ],
  },
  // * * * * * * * 
  // COBRANZA
  // * * * * * * * 
  {
    key: 'cobranza',
    label: 'sidebar.cobranza',
    leftIcon: 'ion-android-clipboard',
    //roles:[ "Admin", "Director", "ContabilidadS", "Cobranza"],
    children: [
      {
        key: 'cobranza_settings',
        label: 'sidebar.settings',
        leftIcon: 'ion-android-clipboard',
        privilegio: "cobranza_configuracion_ver"
      },
      {
        key: 'cobranza_submenu_facturacion',
        label: 'sidebar.cobranza.facturacion',
        privilegio:"*",
        children: [
          {
            key: 'cobranza_factura',
            label: 'sidebar.crear_factura',
            leftIcon: 'ion-android-clipboard',
            privilegio: "cobranza_facturas_ver"
          },
          {
            key: 'cobranza_factura_libre',
            label: 'sidebar.crear_factura_libre',
            leftIcon: 'ion-android-clipboard',
            privilegio: "cobranza_facturas_ver"
          },
          {
            key: 'cobranza_facturas',
            label: 'sidebar.notas_cancelaciones',
            leftIcon: 'ion-android-clipboard',
            privilegio: "cobranza_facturas_ver"
          },
        ]
      },
      {
        key: 'cobranza_submenu_pagos',
        label: 'sidebar.cobranza.pagos',
        privilegio:"*",
        children: [
          {
            key: 'accounts_receivable',
            label: 'sidebar.accounts_receivable',
            leftIcon: 'ion-android-clipboard',
            privilegio: "cobranza_rep_ver" 
          },
          {
            key: 'cobranza_facturas_pagos',
            label: 'sidebar.facturas_pagos',
            leftIcon: 'ion-android-clipboard',
            privilegio: "cobranza_pagos_administrativos_ver" 
          }
        ]
      }
    ],
  },
  // * * * * * * * 
  // REPORTES
  // * * * * * * * 
  {
    key: 'reports',
    label: 'sidebar.reports',
    leftIcon: 'ion-android-clipboard',
    //roles:[ "Admin", "Director", "Vendedor", "VendedorPrecios", "VendedorOperaciones", "Despachador", "Operaciones", "OperacionesAnahuac", "Cliente", "Mayoreo", "Mayoreo1", "Contabilidad", "Anahuac", "ContabilidadS", "Cobranza"],
    children: [
      {
        key: 'charges_report',
        label: 'sidebar.charges',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_cargas" 
      },
      {
        key: 'litros_cliente_report',
        label: 'sidebar.litros_cliente_report',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_litros_cliente" 
      },
      {
        key: 'charges_wholesale_report',
        label: 'sidebar.charges_wholesale',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_cargas_mayoreo" 
      },
      {
        key: 'entries_report',
        label: 'sidebar.inventory',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_entradas" 
      },
      {
        key: 'departures_report',
        label: 'sidebar.departures',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_salidas" 
      },
      {
        key: 'tanks_report',
        label: 'sidebar.tanks',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_tanques" 
      },
      {
        key: 'deliveries_report',
        label: 'sidebar.deliveries',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_estado_pedidos_mayoreo" 
      },
      {
        key: 'uba_report',
        label: 'sidebar.uba',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_uba" 
      },
      {
        key: 'dispatches_report',
        label: 'sidebar.dispatch',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_despacho" 
      },
      {
        key: 'dispatches_vales_report',
        label: 'sidebar.vale',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_vales" 
      },
      {
        key: 'promedio_ventas_report',
        label: 'sidebar.promedio_ventas',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_promedio_ventas" 
      },
      {
        key: 'movimientos_bancarios_report',
        label: 'sidebar.movimientos_bancarios_report',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_movimientos_bancarios" 
      },
      {
        key: 'factura_cobranza_report',
        label: 'sidebar.factura_cobranza_report',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_cobranza" 
      },
      {
        key: 'reportes_proveedores',
        label: 'sidebar.proveedores',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_proveedores" 
      },
      {
        key: 'reportes_comisiones',
        label: 'sidebar.reportes_comisiones',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_comisiones" 
      },
      {
        key: 'reportes_historialcargas',
        label: 'sidebar.reportes_historialcargas',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_historialcargas" 
      },
      {
        key: 'factura_porpagar_report',
        label: 'sidebar.factura_porpagar_report',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_pronostico_cobranza" 
      },
      {
        key: 'factura_report',
        label: 'sidebar.factura_report',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_facturas" 
      },
      {
        key: 'factura_cliente_report',
        label: 'sidebar.factura_cliente_report',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_facturas_clientes" 
      },
      {
        key: 'vencimiento_semanal_report',
        label: 'sidebar.vencimiento_semanal_report',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_vencimiento_semanal" 
      },
      {
        key: 'tipos_factura_report',
        label: 'sidebar.tipos_factura_report',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_tipos_facturas" 
      },
      {
        key: 'factura_cre_report',
        label: 'sidebar.factura_cre_report',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_facturas_cre" 
      },
      {
        key: 'carta_porte_report',
        label: 'sidebar.carta_porte_report',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_facturas" 
      },
      {
        key: 'ordenesdecompra_report',
        label: 'sidebar.ordenesdecompra_report',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_ordenes_compra" 
      },
      {
        key: 'ordenesdecompradetalle_report',
        label: 'sidebar.ordenesdecompradetalle_report',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_ordenes_compra_detalle" 
      },
      {
        key: 'ubsalidasmayoreo_report',
        label: 'sidebar.ubsalidasmayoreo_report',
        leftIcon: 'ion-android-clipboard',
        privilegio: "reportes_ubsalidamayoreo" 
      },
      /*
      {
        key: 'pemex_report',
        label: 'sidebar.pemex',
        leftIcon: 'ion-android-clipboard',
        roles:[ "Admin","Director","Mayoreo" ]
      },
      */
    ],
  },
];

export default options;

