import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Row, Col, Statistic } from "antd";
import appActions from "../../redux/app/actions";
import TopbarNotification from "./topbarNotification";
import TopbarLitresAlert from "./topbarLitresAlert";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import LogoImg from '../../image/logo_1.png';
import Button from "../../components/uielements/button";
import Alert from "../../components/feedback/alert";
import moment from "moment";
import httpHandler from "../../api/asyncHttpHandler.js";
import binaryHttpHandler from "../../api/asyncBinaryHttpHandler.js";
import Modals from "../../components/feedback/modal";
import ModalStyle from "../../containers/Feedback/Modal/modal.style";
import WithDirection from "../../settings/withDirection";
import { siteConfig } from "../../settings";
import MessageHandler from "../../helpers/messages.js";

const { Header } = Layout;
const { toggleCollapsed } = appActions;

class Topbar extends Component {

  confirm = Modals.confirm;
  isoModal = ModalStyle(Modals);
  Modal = WithDirection(this.isoModal);
    state = {
      rol: '',
      loading: false,
      showShift: false
    };
  constructor(props) {
    super(props);
  }
  componentDidMount(){
    let userRec = localStorage.getItem('user');
    userRec = JSON.parse(userRec);

    this.setState({
      rol: userRec.rol,
      showShift: userRec.rol != "Cliente" && (userRec.rol==="Admin" || userRec.rol==="Despachador"),
      //showShift: true
    });
    if(userRec.rol != 'Cliente' && userRec.rol != 'Mayoreo' && userRec.rol != 'Mayoreo1')
      this.doGetCurrentShift();
  }

  doCerrarTurno = async () => {
    this.setState({loading: true});
    const parsedResponse = await httpHandler.petition('closeShift', 'POST', {
      endDate: moment().format("YYYY-MM-DD HH:mm:ss")
    });
    this.setState({loading: false});
    if(parsedResponse === null || parsedResponse.HasError === true) return;
    this.setState({
      openShift:false
    });
    this.obtenerReporteTurno(parsedResponse.id);
    MessageHandler.showSuccess("Has finalizado el turno!");
    
  }
  confirmCerrarTurno = () =>{
    this.confirm({
      title: "Cerrar turno",
      width: 600,
      content:
        "¿Estás seguro que quieres cerrar el turno?",
      onOk: () => { this.doCerrarTurno() },
      onCancel() {},
      okText: "Sí",
      cancelText: "No"
    });
  }
  obtenerReporteTurno = async (id) => {
    this.setState({loading: true});
    const response = await binaryHttpHandler.binaryPetition('createShiftReport', 'POST', {
      shift_id: id,
    });
    this.setState({loading: false});
    if(response === null || response.HasError === true) return;

    let blob = new Blob([response]);
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = 'Cierre_de_turno_'+moment().format("YYYY-MM-DD")+'.pdf';
    a.target = '_blank';
    a.click();
  }
  confirmCerrarTurno = this.confirmCerrarTurno.bind(this);
  doGetCurrentShift = async () => {
    let userObj = localStorage.getItem('user');
    if (userObj)
      userObj = JSON.parse(userObj);
    else return;
    this.setState({loading: true});
    const parsedResponse = await httpHandler.petition('getCurrentShift', 'POST', {
      userId: userObj.id,
    });
    this.setState({loading: false});
    if(parsedResponse === null || parsedResponse.HasError === true) return;

    if (parsedResponse.id !== undefined && parsedResponse.id !== null){
      this.setState({
        openShift:true
      });
    }else{
      this.setState({
        openShift:false
      });
    }
  }

  render() {
    const { toggleCollapsed, url, customizedTheme, locale } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;

    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70
    };

    
    
    const doAbrirTurno = async () => {
      let userObj = localStorage.getItem('user');
      if (userObj)
        userObj = JSON.parse(userObj);
      else return;

      this.setState({loading: true});
      const parsedResponse = await httpHandler.petition('openShift', 'POST', {
        userId: userObj.id,
        initDate: moment().format("YYYY-MM-DD HH:mm:ss")
      });
      this.setState({loading: false});
      if(parsedResponse === null || parsedResponse.HasError === true) return;
      this.setState({
        openShift:true
      });      
    }
  
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">
            <button
              className={
                collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
              }
              style={{ color: customizedTheme.textColor }}
              onClick={toggleCollapsed}
            />
            <div className={siteConfig.production ? "show" : "hide"} >
              <img alt="#" src={LogoImg} className="top-bar-logo" />
            </div>
            <div className={siteConfig.production ? "hide" : "show"} >
              <div style={{color:"red", lineHeight: 'normal', size:"1.2em", marginLeft: '1rem',  marginRight: '1rem', fontWeight:"bold", textAlign: 'center'}}>
                { "Pruebas" }
              </div>
            </div>
            {
              (this.state.showShift) &&
              <Row>
                <Col xs={0} md={12}>
                  <Button loading={this.state.loading} type="primary" className={this.state && this.state.openShift ? "hide" : "show"} onClick={doAbrirTurno}>
                    Abrir Turno
                  </Button>
                  <Button loading={this.state.loading} type="danger" className={this.state && this.state.openShift ? "show" : "hide"} onClick={this.confirmCerrarTurno}>
                    Cerrar Turno
                  </Button>
                </Col>
              </Row>
            }
          </div>

          <ul className="isoRight" style={{paddingLeft:'0'}}>
            <li
              onClick={() => this.setState({ selectedItem: "litresalert" })}
              className="isoMsg">
              <TopbarLitresAlert locale={locale} showTank={this.state && this.state.rol != "Cliente" && this.state.rol != "Mayoreo" && this.state.rol != "Mayoreo1"}/>
            </li>
            {
              (this.state && this.state.rol != "Cliente" ) &&
              <>
                <li
                  onClick={() => this.setState({ selectedItem: "notification" })}
                  className="isoNotify">
                  <TopbarNotification locale={locale} />
                </li>
              </>
            }
            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser locale={locale} />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

// TODO: warning por problema de cambio en state
export default connect(
  state => ({
    ...state.App,
    locale: state.LanguageSwitcher.language.locale,
    customizedTheme: state.ThemeSwitcher.topbarTheme,
    openShift: false
  }),
  { toggleCollapsed }
)(Topbar);
