import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';
import customRoutes from '../../customApp/router';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../Dashboard/Admin')),
  },
  {
    path: 'inbox',
    component: asyncComponent(() => import('../Mail')),
  },
  {
    path: 'mailbox',
    component: asyncComponent(() => import('../Mail')),
  },
  {
    path: 'calendar',
    component: asyncComponent(() => import('../Calendar/Calendar')),
  },
  {
    path: 'googlemap',
    component: asyncComponent(() => import('../Map/GoogleMap/googleMap')),
  },
  {
    path: 'leafletmap',
    component: asyncComponent(() => import('../Map/Leaflet/leaflet')),
  },
  {
    path: 'table_ant',
    component: asyncComponent(() => import('../Tables/antTables')),
  },


  // - - - - - - - Pension paths - - - - - - - - - - 
  {
    path: 'dashboard_director',
    component: asyncComponent(() => import('../Dashboard/Director')),
  },
  {
    path: 'users',
    component: asyncComponent(() => import('../Users')),
  },
  {
    path: 'operators',
    component: asyncComponent(() => import('../Operators')),
  },
  {
    path: 'mapprices',
    component: asyncComponent(() => import('../MapPrices')),
  },
  {
    path: 'units',
    component: asyncComponent(() => import('../Units')),
  },
  {
    path: 'customers',
    component: asyncComponent(() => import('../Customers')),
  },
  {
    path: 'dispatch/:dispatches_id?',
    component: asyncComponent(() => import('../Dispatch')),
  },
  {
    path: 'payments',
    component: asyncComponent(() => import('../Payments')),
  },
  {
    path: 'movimientos_bancarios',
    component: asyncComponent(() => import('../MovimientosBancarios')),
  },
  {
    path: 'productos',
    component: asyncComponent(() => import('../Pension/Productos')),
  },
  {
    path: 'preauthorization',
    component: asyncComponent(() => import('../Preauthorization')),
  },
  {
    path: 'preauthorizationWholesale',
    component: asyncComponent(() => import('../PreauthorizationWholesale')),
  },
  {
    path: 'cartasporte',
    component: asyncComponent(() => import('../CartasPorte')),
  },
  {
    path: 'charges',
    component: asyncComponent(() => import('../Charges')),
  },
  {
    path: 'chargesWholesale',
    component: asyncComponent(() => import('../ChargesWholesale')),
  },
  {
    path: 'orders_management',
    component: asyncComponent(() => import('../OrdersManagement')),
  },
  {
    path: 'shifts',
    component: asyncComponent(() => import('../Shifts')),
  },
  {
    path: 'reservations',
    component: asyncComponent(() => import('../Reservations')),
  },
  {
    path: 'alerts',
    component: asyncComponent(() => import('../Alerts')),
  },
  {
    path: 'prices',
    component: asyncComponent(() => import('../Prices')),
  },
  {
    path: 'egresos_requisicion_descripcion',
    component: asyncComponent(() => import('../Egresos/Descripciones')),
  },
  {
    path: 'egresos_requisicion_grupo',
    component: asyncComponent(() => import('../Egresos/Grupos')),
  },
  {
    path: 'egresos_requisicion_proveedores',
    component: asyncComponent(() => import('../Egresos/Proveedores')),
  },
  {
    path: 'egresos_requisiciones',
    component: asyncComponent(() => import('../Egresos/Requisiciones')),
  },
  {
    path: 'egresos_autorizar_requisiciones',
    component: asyncComponent(() => import('../Egresos/AutorizarRequisiciones')),
  },
  {
    path: 'egresos_requisiciones_autorizadas',
    component: asyncComponent(() => import('../Egresos/RequisicionesAutorizadas')),
  },
  {
    path: 'egresos_requisiciones_agregar',
    component: asyncComponent(() => import('../Egresos/Requisiciones/agregar.js')),
  },
  {
    path: 'egresos_requisiciones_comparativa/:id?',
    component: asyncComponent(() => import('../Egresos/RequisicionesAutorizadas/comparativa.js')),
  },
  {
    path: 'egresos_ordenesdecompra',
    component: asyncComponent(() => import('../Egresos/Ordenesdecompra')),
  },
  {
    path: 'egresos_ordenesdecompra_prefijos',
    component: asyncComponent(() => import('../Egresos/PrefijosOC')),
  },
  {
    path: 'egresos_requisiciones_editar/:id?',
    component: asyncComponent(() => import('../Egresos/Requisiciones/editar.js')),
  },
  {
    path: 'egresos_requisiciones_ver/:id?',
    component: asyncComponent(() => import('../Egresos/AutorizarRequisiciones/ver.js')),
  },
  {
    path: 'egresos_requisicion_unidad',
    component: asyncComponent(() => import('../Egresos/Unidades')),
  },
  {
    path: 'egresos_requisicion_destinos',
    component: asyncComponent(() => import('../Egresos/Destinos')),
  },
  {
    path: 'egresos_razones_sociales',
    component: asyncComponent(() => import('../Egresos/RazonesSociales')),
  },
  {
    path: 'egresos_requisicion_equipos',
    component: asyncComponent(() => import('../Egresos/Equipos')),
  },
  {
    path: 'charges_report',
    component: asyncComponent(() => import('../Reports/Charges')),
  },
  {
    path: 'reportes_proveedores',
    component: asyncComponent(() => import('../Reports/Proveedores')),
  },
  {
    path: 'litros_cliente_report',
    component: asyncComponent(() => import('../Reports/LitrosCliente')),
  },
  {
    path: 'promedio_ventas_report',
    component: asyncComponent(() => import('../Reports/PromedioVentas')),
  },
  {
    path: 'reportes_comisiones',
    component: asyncComponent(() => import('../Reports/Comisiones')),
  },
  {
    path: 'reportes_historialcargas',
    component: asyncComponent(() => import('../Reports/HistorialCargas')),
  },
  {
    path: 'charges_wholesale_report',
    component: asyncComponent(() => import('../Reports/ChargesWholesale')),
  },
  {
    path: 'entries_report',
    component: asyncComponent(() => import('../Reports/Entries')),
  },
  {
    path: 'mayoreo_cartas_porte_remitentes',
    component: asyncComponent(() => import('../Remitentes')),
  },
  {
    path: 'ubsalidasmayoreo_report',
    component: asyncComponent(() => import('../Reports/UBSalidasMayoreo')),
  },
  {
    path: 'departures_report',
    component: asyncComponent(() => import('../Reports/Departures')),
  },
  {
    path: 'tanks_report',
    component: asyncComponent(() => import('../Reports/Tanks')),
  },
  {
    path: 'dispatches_report',
    component: asyncComponent(() => import('../Reports/Dispatches')),
  },
  {
    path: 'movimientos_bancarios_report',
    component: asyncComponent(() => import('../Reports/MovimientosBancarios')),
  },
  {
    path: 'factura_cobranza_report',
    component: asyncComponent(() => import('../Reports/FacturaCobranza')),
  },
  {
    path: 'factura_porpagar_report',
    component: asyncComponent(() => import('../Reports/FacturaPorpagar')),
  },
  {
    path: 'factura_report',
    component: asyncComponent(() => import('../Reports/Factura')),
  },
  {
    path: 'factura_cliente_report',
    component: asyncComponent(() => import('../Reports/FacturasCliente')),
  },
  {
    path: 'vencimiento_semanal_report',
    component: asyncComponent(() => import('../Reports/VencimientoSemanal')),
  },
  {
    path: 'tipos_factura_report',
    component: asyncComponent(() => import('../Reports/TiposFactura')),
  },
  {
    path: 'carta_porte_report',
    component: asyncComponent(() => import('../Reports/CartasPorte')),
  },
  {
    path: 'factura_cre_report',
    component: asyncComponent(() => import('../Reports/FacturaCRE')),
  },
  {
    path: 'dispatches_vales_report',
    component: asyncComponent(() => import('../Reports/DispatchesVales')),
  },
  {
    path: 'anahuac_remission',
    component: asyncComponent(() => import('../AnahuacRemission')),
  },
  {
    path: 'deliveries_report',
    component: asyncComponent(() => import('../Reports/Deliveries')),
  },
  {
    path: 'uba_report',
    component: asyncComponent(() => import('../Reports/Uba')),
  },
  {
    path: 'pemex_report',
    component: asyncComponent(() => import('../Reports/Pemex')),
  },
  {
    path: 'ordenesdecompra_report',
    component: asyncComponent(() => import('../Reports/ODC')),
  },
  {
    path: 'ordenesdecompradetalle_report',
    component: asyncComponent(() => import('../Reports/ODCDetalle')),
  },
  {
    path: 'profile',
    component: asyncComponent(() => import('../Profile')),
  },
  {
    path: 'create_suggestion',
    component: asyncComponent(() => import('../CreateSuggestion')),
  },
  {
    path: 'suggestions',
    component: asyncComponent(() => import('../Suggestions')),
  },
  {
    path: 'cash_movement',
    component: asyncComponent(() => import('../CashMovement')),
  },
  {
    path: 'gallery',
    component: asyncComponent(() => import('../Gallery')),
  },
  {
    path: 'inventory',
    component: asyncComponent(() => import('../Inventory')),
  },
  {
    path: 'inventory_pemex',
    component: asyncComponent(() => import('../InventoryPemex')),
  },
  {
    path: 'cobranza_settings',
    component: asyncComponent(() => import('../Cobranza/Settings')),
  },
  {
    path: 'cobranza_factura',
    component: asyncComponent(() => import('../Cobranza/Factura')),
  },
  {
    path: 'cobranza_factura_libre',
    component: asyncComponent(() => import('../Cobranza/FacturaLibre')),
  },
  {
    path: 'cobranza_facturas_pagos',
    component: asyncComponent(() => import('../Cobranza/FacturasPagos')),
  },
  {
    path: 'cobranza_facturas',
    component: asyncComponent(() => import('../Cobranza/Facturas')),
  },
  {
    path: 'accounts_receivable',
    component: asyncComponent(() => import('../Cobranza/AccountsReceivable')),
  },
  // - - - - - - - Pension paths - - - - - - - - - - 


  {
    path: 'allFormComponent',
    component: asyncComponent(() => import('../Forms/allComponents/')),
  },
  {
    path: 'InputField',
    component: asyncComponent(() => import('../Forms/Input')),
  },
  {
    path: 'editor',
    component: asyncComponent(() => import('../Forms/editor/')),
  },
  {
    path: 'stepperForms',
    component: asyncComponent(() => import('../Forms/StepperForms/')),
  },
  {
    path: 'FormsWithValidation',
    component: asyncComponent(() => import('../Forms/FormsWithValidation')),
  },
  {
    path: 'progress',
    component: asyncComponent(() => import('../Forms/Progress')),
  },
  {
    path: 'button',
    component: asyncComponent(() => import('../Forms/Button')),
  },
  {
    path: 'tab',
    component: asyncComponent(() => import('../Forms/Tab')),
  },
  {
    path: 'autocomplete',
    component: asyncComponent(() => import('../Forms/AutoComplete')),
  },
  {
    path: 'checkbox',
    component: asyncComponent(() => import('../Forms/Checkbox')),
  },
  {
    path: 'radiobox',
    component: asyncComponent(() => import('../Forms/Radiobox/')),
  },
  {
    path: 'selectbox',
    component: asyncComponent(() => import('../Forms/Select/')),
  },
  {
    path: 'transfer',
    component: asyncComponent(() => import('../Forms/Transfer/')),
  },
  {
    path: 'gridLayout',
    component: asyncComponent(() => import('../Box/GridLayout')),
  },
  {
    path: 'notes',
    component: asyncComponent(() => import('../Notes')),
  },
  {
    path: 'todo',
    component: asyncComponent(() => import('../Todo')),
  },
  {
    path: 'articles',
    component: asyncComponent(() => import('../FirestoreCRUD/Article')),
  },
  {
    path: 'investors',
    component: asyncComponent(() => import('../FirestoreCRUD/Investor')),
  },
  {
    path: 'contacts',
    component: asyncComponent(() => import('../Contacts')),
  },
  {
    path: 'alert',
    component: asyncComponent(() => import('../Feedback/Alert')),
  },
  {
    path: 'modal',
    component: asyncComponent(() => import('../Feedback/Modal/')),
  },
  {
    path: 'message',
    component: asyncComponent(() => import('../Feedback/Message')),
  },
  {
    path: 'notification',
    component: asyncComponent(() => import('../Feedback/Notification')),
  },
  {
    path: 'Popconfirm',
    component: asyncComponent(() => import('../Feedback/Popconfirm')),
  },
  {
    path: 'spin',
    component: asyncComponent(() => import('../Feedback/Spin')),
  },
  {
    path: 'shuffle',
    component: asyncComponent(() => import('../Shuffle')),
  },
  {
    path: 'affix',
    component: asyncComponent(() => import('../Navigation/affix')),
  },
  {
    path: 'breadcrumb',
    component: asyncComponent(() =>
      import('../Uielements/Breadcrumb/breadcrumb')
    ),
  },
  {
    path: 'backToTop',
    component: asyncComponent(() => import('../Navigation/backToTop')),
  },
  {
    path: 'dropdown',
    component: asyncComponent(() => import('../Uielements/Dropdown/dropdown')),
  },
  {
    path: 'op_badge',
    component: asyncComponent(() => import('../Uielements/Badge')),
  },
  {
    path: 'op_card',
    component: asyncComponent(() => import('../Uielements/Card')),
  },
  {
    path: 'op_carousel',
    component: asyncComponent(() => import('../Uielements/Carousel')),
  },
  {
    path: 'op_collapse',
    component: asyncComponent(() => import('../Uielements/Collapse')),
  },
  {
    path: 'op_tooltip',
    component: asyncComponent(() => import('../Uielements/Tooltip/')),
  },
  {
    path: 'rating',
    component: asyncComponent(() => import('../Uielements/rating/')),
  },
  {
    path: 'tree',
    component: asyncComponent(() => import('../Uielements/Tree/')),
  },
  {
    path: 'op_tag',
    component: asyncComponent(() => import('../Uielements/Tag')),
  },
  {
    path: 'op_timeline',
    component: asyncComponent(() => import('../Uielements/Timeline')),
  },
  {
    path: 'op_popover',
    component: asyncComponent(() => import('../Uielements/Popover')),
  },
  {
    path: 'googleChart',
    component: asyncComponent(() => import('../Charts/googleChart')),
  },
  {
    path: 'reecharts',
    component: asyncComponent(() => import('../Charts/recharts')),
  },
  {
    path: 'menu',
    component: asyncComponent(() => import('../Navigation/menu')),
  },
  {
    path: 'ReactChart2',
    component: asyncComponent(() => import('../Charts/reactChart2')),
  },
  {
    path: 'pagination',
    component: asyncComponent(() =>
      import('../Uielements/Pagination/pagination')
    ),
  },
  {
    path: 'card',
    component: asyncComponent(() => import('../Ecommerce/card')),
  },
  {
    path: 'cart',
    component: asyncComponent(() => import('../Ecommerce/cart')),
  },
  {
    path: 'checkout',
    component: asyncComponent(() => import('../Ecommerce/checkout')),
  },
  {
    path: 'shop',
    component: asyncComponent(() =>
      import('../Ecommerce/algolia/instantSearch')
    ),
  },
  {
    path: 'reactDates',
    component: asyncComponent(() =>
      import('../AdvancedUI/ReactDates/reactDates')
    ),
  },
  {
    path: 'codeMirror',
    component: asyncComponent(() => import('../AdvancedUI/codeMirror')),
  },
  {
    path: 'uppy',
    component: asyncComponent(() => import('../AdvancedUI/uppy')),
  },
  {
    path: 'dropzone',
    component: asyncComponent(() => import('../AdvancedUI/dropzone')),
  },
  {
    path: 'youtubeSearch',
    component: asyncComponent(() => import('../YoutubeSearch')),
  },
  {
    path: 'frappeChart',
    component: asyncComponent(() => import('../Charts/frappeChart')),
  },
  {
    path: 'invoice/:invoiceId',
    component: asyncComponent(() => import('../Invoice/singleInvoice')),
  },
  {
    path: 'invoice',
    component: asyncComponent(() => import('../Invoice')),
  },
  {
    path: 'chat',
    component: asyncComponent(() => import('../Chat')),
  },
  ...customRoutes,
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
