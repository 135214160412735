import { Map } from 'immutable';

export function clearToken() {
  localStorage.removeItem('id_token');
}

export function getToken() {
  try {
    const idToken = localStorage.getItem('id_token');
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = number => {
    return number > 1 ? 's' : '';
  };
  const number = num => (num > 9 ? '' + num : '0' + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}
export function formatNumber(num, round = true) {
  if(num === null) return;
  let auxNumber = Number(num);
  if(Number.isNaN(auxNumber)) return num;
  if(!round) return auxNumber.toFixedNoRounding(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  let aux = auxNumber.toFixed(2);
  return aux.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
export function formatTime(date) {
  if(!date) return;
  var auxdate = new Date(date);
  let hours = auxdate.getHours();
  let minutes = auxdate.getMinutes();
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes;
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  } else if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

export function toFixedCustom(number, digits){
  if(number === null) return;
  let auxNumber = Number(number);
  if(Number.isNaN(auxNumber)) return number;
  return Number(auxNumber.toFixedNoRounding(digits));
}

export function roundUpDown(number, digits = 2) {
  let by = Math.pow(10, digits)
  return Math.round(number * by) / by;
}
export function roundUp(number, digits = 2) {
  let by = Math.pow(10, digits)
  return Math.ceil(number * by) / by;
}
export function roundDown(number, digits = 2) {
  let by = Math.pow(10, digits)
  return Math.floor(number * by) / by;
}

Number.prototype.toFixedNoRounding = function(n) {
  const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g")
  const a = this.toString().match(reg)[0];
  const dot = a.indexOf(".");
  if (dot === -1) { // integer, insert decimal dot and pad up zeros
      return a + "." + "0".repeat(n);
  }
  const b = n - (a.length - dot) + 1;
  return b > 0 ? (a + "0".repeat(b)) : a;
}

export function getTaxes(amount = 0, value = 0, tax = 0, round = true) {

  let importe = round ? roundUp(amount * value) : toFixedCustom(amount * value, 2);
  let ieps = round ? roundUp(amount * tax) : toFixedCustom(amount * tax, 2);
  let iva = round ? roundUp(importe * 0.16) : toFixedCustom(importe * 0.16, 2);

  return { importe, ieps, iva };
}
export function getTaxesDecimals(amount = 0, value = 0, tax = 0, round = true) {

  let importe = round ? roundUp(amount * value, 6) : toFixedCustom(amount * value, 6);
  let ieps = round ? roundUp(amount * tax, 6) : toFixedCustom(amount * tax, 6);
  let iva = round ? roundUp(importe * 0.16, 6) : toFixedCustom(importe * 0.16, 6);

  return { importe, ieps, iva };
}
export function abbreviateNumber(value) {
  var newValue = value;
  if (value >= 1000) {
      var suffixes = ["", "K", "M", "B","T"];
      var suffixNum = Math.floor( (""+value).length/3 );
      var shortValue = '';
      for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
          var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
          if (dotLessShortValue.length <= 2) { break; }
      }
      if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
      newValue = shortValue+suffixes[suffixNum];
  }
  return newValue;
}