import { apiSettings } from "../settings";
import MessageHandler from "../helpers/messages.js";
import authAction from "../../src/redux/auth/actions";
const HEADERS = {
    'Accept': 'application/pdf'
};
const { logout } = authAction;
async function handleResponses(response) {
  let errorTitle = "Codigo: " + response.status + " - " + response.statusText;
  errorTitle = "<span style='color:gray; font-size: 0.9em;'>" + errorTitle + "</span>";
  let message = "Ocurrio un problema en el servidor, por favor si puede ponerse en contacto con un administrador y reportar la situación";
  switch(response.status) {
    case 200: // OK
    case 201: // Created
    case 202: // Accepted
    case 203: // Non-Authorative Information
    case 204: // No Content
    case 205: // Reset Content
    case 206: // Partial Content
      return response;
    case 300: // Multiple Choice
    case 301: // Moved Permanently
    case 302: // Found
    case 303: // See Other
    case 304: // Not Modified
    case 307: // Temporary Redirect
    case 308: // Permanent Redirect
      return response;
    case 400: // Bad Request
    case 401: // Unauthorized
    case 403: // Forbidden
    case 404: // Not Found
    case 405: // Method Not Allowed
    case 406: // Not Acceptable
    case 407: // Proxy Authentication Required
    case 408: // Request Timeout
    case 409: // Conflict
    case 410: // Gone
    case 411: // Length Required
    case 412: // Precondition Failed
    case 413: // Payload Too Large
    case 414: // URI Too Long
    case 415: // Unsupported Media Type
    case 416: // Range Not Satisfiable
    case 417: // Expectation Failed
    case 418: // I'm a teapot
    case 425: // Too Early
    case 426: // Upgrade Required
    case 428: // Precondition Required
    case 429: // Too Many Request
    case 431: // Request Header Fields Too Large
    case 451: // Unavailable For Legal Reasons
    case 500: // Internal Server Error
    case 501: // Not Implemented
    case 502: // Bad Gateway
    case 503: // Service Unavailable
    case 504: // Gateway Timeout
    case 505: // HTTP Version Not Supported
    case 506: // Variant Also Negotiates
    case 510: // Not extended
    case 511: // Network Authentication Required
    default:
      if(response) {
        let json = null
        try {
          json = await response.json();
        } catch {
          throw Error(message + "<br/><br/>"
            + errorTitle);
        }
        if (json.HasError === true || json.Error === true) {
          if (json.Message) message = '<p>'+json.Message+'</p>';
          if (json.ErrorMessage) message = '<p>'+json.ErrorMessage+'</p>';
          if (json.ValidationErrors) {
            let message = '';
            for(let i=0; json.ValidationErrors.length > i; i++) {
              message += '<p>'+json.ValidationErrors[i]+'</p>'
            }
          }
        } else {
          if (json.Message) message = json.Message;
        }
      }
      throw Error(message + "<br/><br/>"
        + errorTitle);
  }
}
export default {
  async binaryPetition(url, method = 'GET', params = null, json=true, displayMessages=true) {
    let headers = HEADERS;
    let body = null;
    let token = localStorage.getItem('token');
    if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
      if(json) {
        headers["Content-type"] = 'application/json';
        body = JSON.stringify(params);
      } else {
        let formData = new FormData();
        if (params != null && typeof(params) === 'object') {
          const types = new Map(Object.entries(params));
          for (let [key, value] of types) {
            formData.append(key, value);
          }
        }
        body = formData;
      }
    } else if (method === 'GET') {

    } else {
      throw 'Method Required';
    }
    let petitionParams = {
      method,
      responseType: 'arraybuffer',
      headers,
      body
    };
    try {
      let response = await fetch(`${apiSettings.url}${url}?token=${token}`, petitionParams)
        .then(handleResponses)
        .then((response) => {
          if(response)
            return response.arrayBuffer();
          else
            return response;
        })
        .catch((error) => {
          console.error({url, method, error, type: "fetch error" });
          if(displayMessages) {
            if(error) {
              MessageHandler.showError(error.message);
              // Caso especial para 401
              if(error.message.search("401")) {
                logout();
              }
            }
          }
          return null;
        });
      if(displayMessages && response) {
        if (response.HasError === true || response.Error === true) {
          if (response.Message) MessageHandler.showError(response.Message);
          if (response.ErrorMessage) MessageHandler.showError(response.ErrorMessage);
          if (response.ValidationErrors) MessageHandler.showErrorList(response.ValidationErrors);
        } else {
          if (response.Message) MessageHandler.showSuccess(response.Message);
        }
      }
      return response;
    } catch (error) {
      console.error({url, method, error, type: "code error"});
      if(displayMessages) {
        if(error)
          MessageHandler.showError(error.message);
      }
      return null;
    }
  },

}