import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import TopbarDropdownWrapper from './topbarDropdown.style';
import httpHandler from '../../api/asyncHttpHandler.js';

import { Icon, Statistic, Row, Col, Typography } from 'antd';
import authAction from '../../redux/auth/actions';
import LiveChat from 'react-livechat';
const { logout } = authAction;

class TopbarUser extends Component {
  state = {
    visible: false,
    firstName: '',
    email: '',
    rol: null
  };
  livechat = null;

  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    let userRec = localStorage.getItem('user');
    userRec = JSON.parse(userRec);
    this.setState({
      firstName: userRec.firstname,
      rol: userRec.rol,
      email: userRec.email
    });
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  doLogout = async () => {
    let user = localStorage.getItem('user');
    let token = localStorage.getItem('token');

    if (user === null || user === undefined || user === '' || token === null || token === undefined || token === '') {
      // undexpected error, user or token are empty
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.props.logout();
      return;
    }
    user = JSON.parse(user);

    await httpHandler.petition('logout', 'POST', { username: user.username });
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    if (this.livechat) {
      this.livechat.hide_chat_window();
    }
    this.livechat = null;
    this.setState({ rol: null });
    this.props.logout();
  };
  render() {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <a className="isoDropdownLink" onClick={this.doLogout} href="# ">
          <IntlMessages id="topbar.logout" />
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <React.Fragment>
        <Popover
          content={content}
          trigger="click"
          visible={this.state.visible}
          onVisibleChange={this.handleVisibleChange}
          arrowPointAtCenter={true}
          placement="bottomLeft"
        >
            <Row type="flex" align="middle" justify="end">
              <Col xs={0} md={16}>
                {this.state.firstName}
              </Col>
              <Col xs={24} md={8}>
                <i style={{ fontSize: '20px', color: 'gray' }} className="ion-android-exit" />
              </Col>
            </Row>
        </Popover>
        {this.state.rol === 'Cliente' && (
          /*
          <LiveChat
            onChatLoaded={(ref) => (this.livechat = ref)}
            license={12399066}
            visitor={{ name: this.state.firstName, email: this.state.email }}
          />
          */
          <div></div>
        )}
      </React.Fragment>
    );
  }
}
export default connect(null, { logout })(TopbarUser);
